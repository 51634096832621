import React from 'react';
import Img from 'gatsby-image';
import BackgroundImg from 'gatsby-background-image';
import styled from 'styled-components';

import { mediaQueries } from '../../theme/mediaQueries';

import RightArrowLink from '../RightArrowLink';
import BlurredBgSection from '../BlurredBgSection';
import Breadcrumb from '../Breadcrumb';

const AlbumHeader = ({ cover, releaseDate, title, links, pathname, parentName }) => {
  const linkList = links.map((link) => (
    <StyledServicesListItem key={link._key}>
      <RightArrowLink text={link.name} url={link.linkUrl} />
    </StyledServicesListItem>
  ));
  return (
    <StyledAlbumHeader>
      <BlurredBgSection fluid={cover} />
      <div className="container" style={{ height: '100%' }}>
        <StyledAlbumHeaderWrapper>
          <StyledAlbumInfoSection>
            <Breadcrumb pathname={pathname} parentName={parentName} />
            <StyledAlbumInfoContainer>
              <StyledAlbumDate>{releaseDate}</StyledAlbumDate>
              <StyledAlbumTitle>{title}</StyledAlbumTitle>
              <StyledServicesList>{linkList}</StyledServicesList>
            </StyledAlbumInfoContainer>
          </StyledAlbumInfoSection>
          <StyledAlbumCoverSection>
            <StyledCoverContainer>
              <Img fluid={cover} />
            </StyledCoverContainer>
          </StyledAlbumCoverSection>
        </StyledAlbumHeaderWrapper>
      </div>
    </StyledAlbumHeader>
  );
};

const StyledAlbumHeader = styled.header`
  background-color: transparent;
  width: 100%;
  position: relative;
`;

// const StyledBackground = styled.div`
//   background-color: black;
//   width: 100%;
//   height: 100%;
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   z-index: -1;
//   overflow: hidden;
// `;

// const StyledBackgroundImg = styled(BackgroundImg)`
//   width: 100%;
//   height: 100%;
//   background-position: center center;
//   background-repeat: no-repeat;
//   background-size: cover;

//   &::before,
//   &::after {
//     filter: blur(30px);
//   }
// `;

// const StyledOverlay = styled.div`
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background: ${(props) => props.theme.colors.black};
//   background: ${(props) => props.theme.gradients.linearOverlay};
// `;

const StyledAlbumHeaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  /* background-color: red; */
  padding-top: 14rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${mediaQueries('lg')`
    flex-direction: row;
  `}
`;

const StyledAlbumInfoSection = styled.section`
  /* background-color: lightblue; */
  width: 100%;
  height: 100%;
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 2rem;
`;

const StyledAlbumInfoContainer = styled.div`
  /* background-color: pink; */
  display: flex;
  flex-direction: column;
  margin-bottom: 3.75rem;
  margin-top: 0.5rem;
`;

const StyledAlbumCoverSection = styled.section`
  /* background-color: lightgreen; */
  width: 100%;
  height: 100%;
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 2rem;
`;

const StyledCoverContainer = styled.span`
  display: flex;
  flex-direction: column;
  margin-bottom: -6vh;

  ${mediaQueries('lg')`
  margin-bottom: -8vh;
  `}
`;

const StyledServicesList = styled.ul`
  margin: 0;
  margin-top: 1.7rem;
  padding: 0;
  list-style: none;
`;

const StyledServicesListItem = styled.li`
  display: inline-block;
  margin: 0.3rem 1.2rem 0 0;
`;

const StyledAlbumTitle = styled.h1`
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.6;
  color: ${(props) => props.theme.colors.white};
  margin-bottom: 0.4rem;
  margin-top: 0.2rem;
`;

const StyledAlbumDate = styled.time`
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0.05em;
  margin-bottom: 0;
  color: ${(props) => props.theme.colors.white};
  text-transform: uppercase;
`;

export default AlbumHeader;

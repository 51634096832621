import React from 'react';
import styled from 'styled-components';

const RecordingFooter = ({
  releaseDate,
  label,
  numOfTracks,
  playTime,
  releaseDateTitle,
  labelTitle,
  numOfTracksTitle,
  totalPlayingTimeTitle,
}) => {
  return (
    <StyledFooter>
      <div className="container">
        <StyledParagraph>
          <span>{releaseDateTitle}:</span> {releaseDate}
          <br />
          <span>{labelTitle}:</span> {label}
          <br />
          <span>{numOfTracksTitle}:</span> {numOfTracks}
          <br />
          <span>{totalPlayingTimeTitle}:</span> {playTime}
        </StyledParagraph>
      </div>
    </StyledFooter>
  );
};

const StyledFooter = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  padding-top: 3rem;
  padding-bottom: 3rem;
  text-align: center;
`;

const StyledParagraph = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;

  & > span {
    font-weight: 300;
  }
`;

export default RecordingFooter;

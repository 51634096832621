import { Spotify } from '@icons-pack/react-simple-icons';
import React from 'react';
import styled from 'styled-components';

import { mediaQueries } from '../../theme/mediaQueries';
import SpotifyEmbed from '../Recording/SpotifyEmbed';
import PortableText from '../portableText';

const RecordingMain = ({ spotifyUrl, description, playerHeight }) => {
  // if (spotifyUrl) {
  //   console.log('There is a spotify album');
  // } else {
  //   console.log('No spotify available');
  // }

  const playerSection = (
    <StyledPlayerSection>
      <SpotifyEmbed albumUrl={spotifyUrl} height={playerHeight} />
    </StyledPlayerSection>
  );
  return (
    <StyledAlbumMain>
      {spotifyUrl ? playerSection : ''}

      <StyledAlbumTextSection spotifyEmbed={spotifyUrl}>
        <PortableText blocks={description} />
      </StyledAlbumTextSection>
    </StyledAlbumMain>
  );
};

const StyledAlbumMain = styled.section`
  display: flex;
  flex-direction: column;

  ${mediaQueries('lg')`
    flex-direction: row;
  `}
`;

const StyledAlbumTextSection = styled.section`
  flex-basis: ${(props) => (props.spotifyEmbed ? '50%' : '100%')};
  margin-bottom: 1rem;
  margin-top: 1.7rem;
  padding: 0 2rem;
  order: 2;

  ${mediaQueries('lg')`
    margin-bottom: 2.9rem;
    margin-top: 8vh;
    order:1;
  `}
`;

const StyledPlayerSection = styled.section`
  flex-basis: 50%;
  height: 100%;
  width: 100%;
  margin-top: 6vh;
  padding: 0 2rem;
  order: 1;

  ${mediaQueries('lg')`
    margin-top: 8vh;
    order:2;
  `}
`;

export default RecordingMain;

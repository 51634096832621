import React from 'react';
import { ImQuotesLeft } from 'react-icons/im';
import { Link } from 'gatsby';
import styled from 'styled-components';

import PortableText from '../portableText';
import ViewMoreBtn from '../Buttons/ViewMoreBtn';

const Quote = ({ review, relatedUrl, linkTxt }) => {
  let quote = '';
  let source = '';
  let link = '';
  // console.log(linkTxt);

  if (review[0]._rawQuote && review[0].source && review[0].slug.current) {
    quote = review[0]._rawQuote[0];
    source = review[0].source;
    link = relatedUrl + review[0].slug.current;
  }
  // quote[0].children[0].text.split('\n')[0].replace(/^(.{150}[^\s]*).*/, '$1');
  // console.log(quote.children[0].text.split('\n')[0]);

  return (
    <StyledBlockquote>
      <ImQuotesLeft />
      <PortableText blocks={quote} /> 
      {/* 
        Add 'continue or something to text block 
        Embed translations for 'read more'
        fix styles
    */}
      <StyledLink to={`${link}`}>
      <ViewMoreBtn btnText={linkTxt} />
      </StyledLink>
      <cite>{source}</cite>
    </StyledBlockquote>
  );
};

const StyledLink = styled(Link)`
  display: inline-block;
  text-align: left;
  font-family: ${(props) => props.theme.fonts.sans};

  & > span.view-more-btn {
    font-size: 1rem!important;
  }
  
  &:hover,
  &:active {
    color: inherit;

    & h3 {
      color: ${(props) => props.theme.colors.primary};
    }

    & .view-more-btn {
      color: ${(props) => props.theme.colors.primary};
      &::before {
        width: 56px;
      }
    }
  }
`

const StyledBlockquote = styled.blockquote`
  margin: 0;
  padding: 0 5%;
  padding-top: 1.875rem;
  padding-bottom: 1.5625rem;
  text-align: center;
  border-top: 1px solid ${(props) => props.theme.colors.grayLight};
  border-bottom: 1px solid ${(props) => props.theme.colors.grayLight};

  & > svg {
    display: block;
    width: 2.5rem;
    height: 1.7rem;
    fill: ${(props) => props.theme.colors.primary};
    margin-left: auto;
    margin-right: auto;
  }
  & > p {
    font-family: ${(props) => props.theme.fonts.serif};
    font-weight: 400;
    font-size: 1.3rem;
    font-style: italic;
    line-height: 1.5;
    margin: 0.9rem 0 0.2rem;
  }
  & > cite {
    display: block;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    font-family: ${(props) => props.theme.fonts.condensed};
    color: ${(props) => props.theme.colors.primary};
    font-style: normal;
    font-weight: 600;
    line-height: 1.6;
    margin-top: 2rem
  }
`;

export default Quote;

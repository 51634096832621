import React from 'react';
import { VscArrowRight } from 'react-icons/vsc';
import styled from 'styled-components';

const RightArrowLink = ({ text, url }) => {
  return (
    <StyledArrowLink href={url} target="_blank">
      {text}{' '}
      <StyledRoundIcon>
        <VscArrowRight size="1.5rem" />
      </StyledRoundIcon>
    </StyledArrowLink>
  );
};

const StyledArrowLink = styled.a`
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 500;
  letter-spacing: 0.05rem;
  color: ${(props) => props.theme.colors.white};

  &:hover,
  &:active {
    color: ${(props) => props.theme.colors.primary};

    & > span {
      color: ${(props) => props.theme.colors.white};
      background-color: ${(props) => props.theme.colors.primary};
      border-color: ${(props) => props.theme.colors.primary};
    }
  }

  &::after {
    height: 0;
  }
`;

const StyledRoundIcon = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border: 1px solid ${(props) => props.theme.colors.white};
  border-radius: 50%;
  margin-left: 0.6rem;
`;

export default RightArrowLink;

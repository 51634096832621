import React from 'react';
import { Link, graphql } from 'gatsby';
import { Container } from 'react-bootstrap';

import localize from '../components/localize';
import { localizeDate } from '../lib/helpers';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Quote from '../components/Recording/Quote';
import RecordingHeader from '../components/RecordingTemplate/RecordingHeader';
import RecordingMain from '../components/RecordingTemplate/RecordingMain';
import PageFooter from '../components/PageFooter';
import ShareButtons from '../components/ShareButtons';
import RecordingFooter from '../components/RecordingTemplate/RecordingFooter';

const Recording = ({ data, pageContext, path, location }) => {
  const {
    title,
    publishedAt,
    labelName,
    playTime,
    numberOfTracks,
    imageOnly,
    _rawDescription,
    spotifyUrl,
    links,
    review,
  } = data.sanityRecording;

  const localeReleaseDate = localizeDate(publishedAt, pageContext.locale, null, false);

  const seoDescription = _rawDescription[0].children[0].text;
  return (
    <Layout pageContext={pageContext} path={path} siteTitle={data.site.siteTitle}>
      <SEO
        title={title}
        description={seoDescription}
        image={imageOnly.asset.fluid}
        imageAlt={`Recording Cover Image "${title}"`}
        lang={pageContext.locale}
        path={path}
        pageType="article"
      />
      <article>
        <RecordingHeader
          cover={imageOnly.asset.fluid}
          title={title}
          links={links}
          releaseDate={localeReleaseDate}
          pathname={location.pathname}
          parentName={data.recordingsPage.title}
        />

        <main className="container">
          <RecordingMain spotifyUrl={spotifyUrl} description={_rawDescription} playerHeight={400} />
        </main>

        {review && review[0] ? (
          <section className="container" style={{ marginTop: '5rem', marginBottom: '5rem' }}>
            <Quote 
            review={review} 
            relatedUrl={pageContext.locale === 'en' ? `/${pageContext.locale}/press/` : `/press/`}
            linkTxt={data.recordingsDictionary.readMore}
            />
          </section>
        ) : (
          ''
        )}

        <RecordingFooter
          releaseDate={localizeDate(publishedAt, pageContext.locale, 'long')}
          label={labelName}
          numOfTracks={numberOfTracks}
          playTime={playTime}
          releaseDateTitle={
            data.recordingsDictionary.releaseDate
              ? data.recordingsDictionary.releaseDate
              : 'Erscheinungsdatum'
          }
          labelTitle={data.recordingsDictionary.label ? data.recordingsDictionary.label : 'Label'}
          numOfTracksTitle={
            data.recordingsDictionary.numberOfTracks
              ? data.recordingsDictionary.numberOfTracks
              : 'Anzahl Tracks'
          }
          totalPlayingTimeTitle={
            data.recordingsDictionary.totalPlayingTime
              ? data.recordingsDictionary.totalPlayingTime
              : 'Gesamtspielzeit'
          }
        />

        <PageFooter>
          <Container className="d-flex justify-content-center flex-wrap">
            <ShareButtons url={location.href} title={title} lang={pageContext.locale} />
          </Container>
        </PageFooter>
      </article>
    </Layout>
  );
};

export const query = graphql`
  query RecordingTemplateQuery($id: String!) {
    site: sanitySiteSettings {
      siteTitle
      siteUrl
    }
    recordingsDictionary: sanityDictionaryRecording {
      releaseDate {
        _type
        de
        en
      }
      label {
        _type
        de
        en
      }
      numberOfTracks {
        _type
        de
        en
      }
      totalPlayingTime {
        _type
        de
        en
      }
      readMore {
        _type
        de
        en
      }
    }
    recordingsPage: sanityPage(id: { eq: "-bec422d0-6822-5b98-b1dc-abfd568abc82" }) {
      title {
        _type
        de
        en
      }
    }
    sanityRecording(id: { eq: $id }) {
      id
      title {
        _type
        de
        en
      }
      publishedAt
      labelName
      playTime
      numberOfTracks
      imageOnly {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      _rawDescription(resolveReferences: { maxDepth: 5 })
      spotifyUrl
      links {
        _key
        name
        linkUrl
      }
      review {
        id
        _rawQuote(resolveReferences: { maxDepth: 5 })
        source
        slug {
          current
        }
      }
    }
  }
`;

export default localize(Recording);

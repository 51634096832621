import React from "react";
import styled from "styled-components";

const SpotifyEmbed = ({ albumUrl, height }) => {
  const spotifyAlbum = new URL(albumUrl).pathname;

  return (
    <div>
      <iframe
        src={`https://open.spotify.com/embed${spotifyAlbum}`}
        width="100%"
        height={height}
        frameBorder={0}
        allowtransparency="true"
        allow="encrypted-media"
      />
    </div>
  );
};

export default SpotifyEmbed;
